<template>
  <b-container fluid>
    <b-row class="h-100 justify-content-center">
      <b-col sm="6" md="5" lg="4" xl="3">
        <b-card>
          <div class="form-signin text-center" v-if="successful.message">
            <img class="mb-3 img-fluid" src="@/assets/logo.png" width="120" />
            <h3 class="mb-3 font-weight-bolder">Password Reset</h3>
            <p>{{ successful.message }}</p>
          </div>

          <form
            class="form-signin text-center"
            @submit.prevent="sendReset"
            v-if="!successful.message"
          >
            <img class="mb-3 img-fluid" src="@/assets/logo.png" width="120" />
            <h3 class="mb-3 font-weight-bolder">Forgot Password</h3>
            <small class="py-2"
              >Please enter your e-mail address, we will send you a reset
              password link.</small
            >
            <b-form-group>
              <b-form-input
                trim
                type="email"
                v-model="$v.form.body.email.$model"
                :state="validateState('email')"
                placeholder="Email Address"
              ></b-form-input>
              <div class="alert alert-success mt-2" v-if="successful.success">
                {{ successful.message }}
              </div>
            </b-form-group>

            <b-button variant="dark" block type="submit">
              Reset Password
            </b-button>
          </form>

          <template v-slot:footer>
            <p class="text-muted text-center small m-0">
              Already have an account? <br />
              click here to
              <router-link to="/login" class="text-dark font-weight-bold"
                >Sign In</router-link
              >
            </p>
          </template>
          <b-overlay :show="isBusy" no-wrap></b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import errorMixins from "../../mixins/notifications/error";
import successMixins from "../../mixins/notifications/success";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "ForgotPassword",
  mixins: [errorMixins, successMixins],
  data() {
    return {
      successful: {
        success: false,
        message: null,
      },
      errors: {
        error: false,
        message: "",
      },
      isBusy: false,
      form: {
        body: {
          email: "",
        },
        staySignedIn: false,
        errors: {},
      },
    };
  },
  validations: {
    form: {
      body: {
        email: {
          required,
          email,
        },
      },
    },
  },
  methods: {
    sendReset() {
      this.$v.form.body.$touch();
      if (!this.$v.form.body.$anyError) {
        this.isBusy = true;
        this.axios
          .post("auth/password-reset-send", {
            email: this.form.body.email,
            source: "intranet",
            type: "email",
          })
          .then((res) => {
            this.successful.success = true;
            this.successful.message =
              "Instructions to reset password have been sent to: " +
              this.form.body.email;
          })
          .catch((error) => {
            this.errorHandler(error.response);
            return [];
          })
          .finally(() => {
            this.isBusy = false;
          });
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form.body[name];
      return $dirty ? !$error : null;
    },
  },
};
</script>
