var errorMixins = {
  methods: {
    errorHandler(res) {
      if (res === undefined) return;

      const code = res.status;

      let title = "Error";
      let text = "something weird happened, please try again";

      if (
        res.data.error &&
        Object.prototype.hasOwnProperty.call(res.data.error, "errors") &&
        Object.keys(res.data.error.errors).length > 0
      ) {
        const errorList = res.data.error.errors;
        text = "";
        for (const key in errorList) {
          errorList[key].forEach(element => {
            text += "<li>" + element + "</li>";
          });
        }
      } else if (res.data.error) {
        if (res.data.error.message) {
          text = res.data.error.message;
        } else {
          text = res.data.error;
        }
      } else {
        text = res.data.message;
      }

      switch (code) {
        case 401:
          title = "Unauthorized Access";
          break;
        case 422:
          title = "Form Error";
          break;
        case 405:
          title = "Not allowed";
          text = "Something weird has happened, please try again";
          break;

        default:
          break;
      }

      this.error(title, text);
    },
    error(titleText, html) {
      this.$swal({
        icon: "error",
        titleText,
        html,
        confirmButtonText: "OKAY"
      });
    }
  }
};

export default errorMixins;
